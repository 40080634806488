<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>{{ $t('general.manage_announcements') }}</li>
        </ul>
      </nav>
    </div>
    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>{{ $t('general.manage_announcements') }} </span>
        </h2>
      </div>
      <div class="section-header-right">
        <router-link :to="{ name: 'company.announcement.add' }" class="btn btn-default">
          <i class="uil-plus"></i>{{ this.$t('notice.add') }}

        </router-link>
      </div>
    </div>
    <template v-if="errorMessages">
      <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ error }}
        </p>
      </div>
    </template>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>

    <div class="chats-container margin-top-0">
      <div class="chats-container-inner">
        <!-- chats -->
        <div class="chats-inbox for_megaphone">
          <div class="chats-headline">
            <div class="input-with-icon">
              <input id="autocomplete-input" type="text" :placeholder="$t('general.search')" v-model="filter.search" />
              <i class="icon-material-outline-search"></i>
            </div>
          </div>

          <ul>
            <li v-for="(item, index) in formattedItem" :key="'announcement_item_' + index">
              <router-link :to="{
                name: 'company.announcement.edit',
                params: { id: item.id },
              }">
                <div class="message-avatar">
                  <i class="uil-megaphone"></i>
                </div>
                <div class="message-by">
                  <div class="message-by-headline">
                    <h5>{{ item.title }}</h5>
                    <span>{{ item.created_at }}</span>
                  </div>
                  <p v-html="item.description"></p>
                  <button type="button" class="btn btn-sm btn-soft-secondary mt-2">
                    <i class="uil-comment-alt-message"></i>{{ $t('general.view') }}</button><button
                    @click.prevent="removeItem(item.id)" type="button" class="btn btn-sm btn-soft-secondary mt-2">
                    <i class="uil-trash-alt"></i> Sil
                  </button>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- chats / End -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import moment from "moment";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  DELETE_ITEM,
} from "@/core/services/store/announcement.module";

export default {
  name: "AnnouncementList",
  components: { Pagination },
  data() {
    return {
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        sort: "id",
        order: "desc",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          title: currentItem.name,
          description: currentItem.text ? currentItem.text : "-",
          status: currentItem.status_id
            ? this.$t("general.active")
            : this.$t("general.passive"),
          created_at: currentItem.created_at
            ? moment(moment(currentItem.created_at).format("YYYY-MM-DD HH:mm")).fromNow()
            : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order: self.filter.order,
        },
      });
    },
    removeItem(id) {
      this.$store.dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
        url: BASE_URL + "/" + id,
      }).then(() => {
        if (!this.error) {
          this.successMessage = this.$t("general.successfully_deleted");
          this.getResult()
        } else this.errorMessages.push(this.error)
      })
    },
    sortOrder(sort, order, is_user = true) {
      let self = this;
      self.page = 1;
      self.filter.sort = sort;
      self.filter.order = order;

      this.getResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>


<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
